<template>
  <div :class="{
    'maincon': true,
    'isBlock': isBlock
  }">

    <div class="plugin">
      <div
        :class="{ pbtn: true, activebtn: 'vector' === currentMapLayer.type }"
        @click="toggleMapType('vector')"
      >
        矢量地图
      </div>
      <div
        :class="{ pbtn: true, activebtn: 'satellite' === currentMapLayer.type }"
        @click="toggleMapType('satellite')"
      >
        影像地图
      </div>
    </div>

    <l-map
      style="width: 100%; height: 100%; z-index: 10"
      :zoom="12"
      :center="center"
    >
      <l-tile-layer :url="currentMapLayer.url"></l-tile-layer>
      <l-tile-layer
        :url="currentMapLayer.texturl"
        :attribution="currentMapLayer.attribution"
      ></l-tile-layer>
      <!-- 地块点 -->
      <l-marker
        v-if="detail.lat_gaode && detail.lng_gaode"
        :name="detail.name"
        :icon="icon(detail.usecate)"
        :lat-lng="[detail.lat_gaode, detail.lng_gaode]"
        :offset="[0, 20]"
      >
      </l-marker>
      <!-- 地库多边形 -->
       <l-polygon
         v-for="item in polygonpath"
         :key="item.id"
        :lat-lngs="item.path"
          :color="item.strokeColor"
        :fill-color="item.filecolor"
        :fill-opacity="0.6"
      >
      </l-polygon>

        <l-marker
          v-for="item in pointsList"
          :key="item.id"
          :icon="icon()"
          :lat-lng="[item.lng_lat_gaode.lat, item.lng_lat_gaode.lng]"
          :offset="[8, 0]"
        >
          <!-- permanent: true // 如果想要提示永久显示，可以设置为true -->
          <l-tooltip
            :options="{
              permanent: true,
              interactive: true,
              offset: [4, -16],
              className: 'color'
            }"
           
          >
            <div class="tooltipInnerBox">
              <img cLass="icon" :src="item.imgurl" />
              <div class="title">
                {{ item.name}}
              </div>
            </div>
          </l-tooltip>
          
        </l-marker>
        <l-polyline v-for="polyline in polylines" :key="polyline.id" :lat-lngs="polyline.path" 
        :opacity="polyline.strokeOpacity"
         :dash-array="polyline.dashArray"
        :color="polyline.strokeColor" :weight="polyline.strokeWeight"></l-polyline>

    </l-map>
  </div>
</template>

<script>

import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LIcon,
} from "vue2-leaflet";
import {
  vectorMapLayer,
  satelliteMapLayer,
  leafletmapurl,
  leafletmaptexturl,
  leafletmapyunxuanurl,
  isgaode,
} from "@/settings.js";

import { getDetail } from "@/api/data";

import { latLng, icon } from "leaflet";
import { pointData, polygonData, lineData } from '@/api/dcity'

export default {
  name:'leafletmaptraffic',
  components: {
    LMap,
    LTileLayer,
    LMarker,  
    LPopup,
    LPolyline,
    LPolygon,
    LIcon,
    LTooltip
  },

  props: {
    pointcate: String,
    linecate: String,
    cname: String,
    // city_plate_id: {
    //   type: Number,
    // },
    isBlock: {
      props: Boolean,
      default: false
    },
    polygondata: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  mounted() {

    this.drawCurrentLand()
    this.getPointData()
    this.getLineData()
  },
  watch: {

    pointcate(v) {
      this.getPointData()
    },
    linecate() {
      this.getLineData()
    },
    shi(v) {

      if (v !== 0) {
        this.getLineData()
        this.getPointData()
      }
    },

    city_plate_id(v) {


    },
    cname(v) {
      // let myGeo = new BMapGL.Geocoder();
      var that = this
      // myGeo.getPoint(v + '人民政府', function (point) {
      //   if (point) {
      //     that.map.centerAndZoom(point)
      //   } else {
      //     alert('您选择的地址没有解析到结果！');
      //   }
      // }, v + '市')
      that.getPointData()
      that.getPolygonData()

    },




    polygondata: {
      handler(v) {
        // console.log('==data==', v)

        v.map(item => {
          this.handlerData(item.set)
        })
      },
      deep: true
    }


  },

  computed: {

    city_plate_id: {
      get() {
        let a = this.$store.state.currentLandData.cid
        return a
      },
    },

    shi: {
      get() {
        let a = this.$store.state.currentLandData.cid
        return a
      },

    },

    currentLandData: {
      get() {
        return this.$store.state.currentLandData
      }
    }

    // currentArea: {
    //   get() {
    //     // alert(this.$store.state.currentArea)
    //     return this.$store.state.currentArea
    //   }
    // }
  },
  data() {

    return {
      isgaode: isgaode,
      //矢量地图对象
      vectorMapLayer: vectorMapLayer,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer: {
        url: "",
        texturl: "",
        attribution: "",
        type: "vector",
      },


      polygonpath: [],
      markerLatLng: L.latLng(),
      centerpoint: [32.1212,120.1212],   
      crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
      url: leafletmapurl,
      attribution: "",
      staticAnchor: [16, 37],
      center: [0, 0],
      zoom: 18,
      detail:{},
      pointsList:[],
      polylines:[]
    }
  },
    created() {
    this.currentMapLayer = this.vectorMapLayer;
    this.currentMapLayer.type = "vector";
  },

  methods: {
    toggleMapType(type) {
      if (type === "vector") {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = "vector";
      } else if (type === "satellite") {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = "satellite";
      }
    },
        icon(num) {
      if(num == null || num == ''){

        return icon({
          iconUrl: require("@/assets/opcity.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37]
         });
      }else{
        return icon({
          iconUrl: require("@/assets/type" + num + ".png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
     
    },


    handerPolygondata(id,allset) {
      let polygonpath = [];
      const setObj = JSON.parse(allset);
      console.log('setObj',setObj)
      const rowpolygonpath = setObj.map((itemSingle) => {
        // data 是一个多变形的数据
        // 处理一个多变形的方法
        const singlepath = this.handleSingleData(id, itemSingle);
        polygonpath.push(singlepath);
      });
      this.polygonpath  = polygonpath
    },

    handleSingleData(id, data) {
      let obj = {
        id: id,
        path: [],
        filecolor: "#000",
        strokcolor: "#409EFF",
        weight: this.strokeWeight,
        strokeopacity: this.strokeOpacity,
        fillopacity: this.fillOpacity,
      };
      const pointobj = data.lnglat.split(";");
      const pointArr = pointobj.map((point) => {
        const lnglat = point.split(",");
        return [ parseFloat(lnglat[1]) ,parseFloat(lnglat[0])]
      });
      obj.path = pointArr;
      obj.filecolor = data.color;

      return obj;
    },


    //画当前地块的多变形和点
    drawCurrentLand() {
      this.getMapDetail().then(res => {

      })
    },

    getMapDetail() {
      var that = this
      return new Promise(function (resolve, reject) {
        getDetail({ id: that.currentLandData.id,is_gaode: that.isgaode}).then(res => {
          that.detail = res.data

          that.handerPolygondata( res.data.id,res.data.set_gaode)

          that.center = [
						res.data.lat_gaode,
            res.data.lng_gaode
          ]
          resolve(res.data)
        })

      })

    },
    //画点
    drawPoint(itemdata) {

      const type5 = require('@/assets/point.png')

      const myIcon = new BMapGL.Icon(type5, new BMapGL.Size(50, 110), {
        imageOffset: new BMapGL.Size(0, 0)   // 设置图片偏移   
      })

      const point = new BMapGL.Point(parseFloat(itemdata.lng), parseFloat(itemdata.lat));
      // 创建标注对象并添加到地图  
      const marker = new BMapGL.Marker(point, { icon: myIcon });
      marker.id = itemdata.id
      this.map.addOverlay(marker);
      this.map.centerAndZoom(point, 14)
    },


    handleImg(data) {
      const res = data.map(item => {
        item.imgurl = require('@/assets/icon/jiaotong' + item.type + '.png')
        return item
      })
      return res
    },

    //获取点数据
    getPointData() {
      // alert('shi')
      // alert(this.city_plate_id)
      const params = {
        city_plate_id: this.city_plate_id,
        type: this.pointcate,
        is_gaode: this.isgaode
      }

      if (this.city_plate_id) {
        pointData(params).then(res => {
          // console.log('===', res)
          this.pointsList = this.handleImg(res.data)
          console.log('pointsList',this.pointsList)
        })
      }
    },

    getLineData() {

      const params = {
        city_plate_id: this.city_plate_id,
        type: this.linecate,
        is_gaode: this.isgaode
      }


      if (this.shi) {
        lineData(params).then(res => {



          const lineArr = res.data
          const polylineArr = lineArr.map(line => {
            const LinePointArr = JSON.parse(line.set_gaode)
            // console.log('LinePointArr', LinePointArr)
            let path = []
            LinePointArr.map(item => {
              path.push([ item.lat,item.lng])
            })

            //判断虚线和实线
            let  dasharray = ''
            if(line.strokestyle === "dashed"){
              dasharray = '5,10'
            }else{
              dasharray = ''
            }
            return {
              path: path,
              visible: true,
              strokeColor: line.color,	//String	//线条颜色，使用16进制颜色代码赋值。默认值为#006600
              strokeOpacity: Number(line.transparency),	//Number	线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
              strokeWeight: Number(line.width),	//Number	线条宽度，单位：像素
              dashArray:dasharray
            }

          })
          this.polylines = polylineArr

          console.log('LPolylines', this.polylines)

        })
      }
    },

    // 处理数据
    handlerData(data) {
      const dataArr = JSON.parse(data) // 获取地图数据数组
      dataArr.map(obj => {
        this.drawPloygonByBD(obj)
      })
    },




    // 获取手动画 数据库api数据 画多变形
    drawPloygonByBD(obj) {
      // this.map.clearOverlays()// 添加覆盖物

      var ply = new BMapGL.Polygon(obj.multipoint, {
        strokeWeight: 2,
        // strokeColor: obj.strokecolor
        strokeStyle: 'dashed',
        fillColor: '#dedede',
        strokeColor: '#000000'


      }) // 建立多边形覆盖物
      this.blockoverlay = ply
      this.drwaPolygonArr.push(ply)

      this.map.addOverlay(ply) // 添加覆盖物
      // this.map.setViewport(ply.getPath()) // 调整视野
    },






    toChangeLocation() {

    },

    changeCenter() {
      let o = this.bmapManager.getMap()
      console.log(o)
    },

    getPolygonData() {
      // alert(this.currentArea)
      let currentAreaid
      if (this.isBlock) {
        currentAreaid = this.block
      } else {
        currentAreaid = this.currentArea
      }
      // alert('block='+currentAreaid)

      const params = {
        city_plate_id: currentAreaid,
        type: 10
      }

      polygonData(params).then(res => {
        const polygondata = res.data
        polygondata.map(item => {
          this.handlerData(item.set)
        })

      })
    },


    setAreaLine(cname) {
      if (cname) {

        var bdary = new BMapGL.Boundary();
        var that = this;
        bdary.get(cname, function (rs) {
          //这里是用户自己的函数。 

          that.drwaPolygonArr.map(ply => {
            that.map.removeOverlay(ply)
          })
          that.polygons = []
          // alert()
          if (that.isBlock || rs.boundaries.length === 0) { //自定义区域画多变形
            // if (isBlock)
            that.getPolygonData()
          } else {
            const newbounderies = rs.boundaries.map(item => {
              let single = item.split(';');
              let newItem = single.map(v => {
                return v.split(',')
              })
              const object = {
                path: newItem,

              }


              return object;
            })

            that.polygons = newbounderies;
            // that.map.setViewport(newbounderies) // 调整视野
          }

        });
      }

    }




  },


};
</script> 
<style lang="scss" scoped>

.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}
/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}

.maincon {
  position: relative;
  height: calc(100vh - 220px);
}
.tooltipInnerBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon{

    height: 30px;
    width: 30px;
  }
  .title{
    font-size: 16px;
    margin-left:5px;
  }
}





// .isBlock{
//   height: calc(100vh - 20px);
// }

.contrl {
  position: absolute;
  ;
  right: 0;
  top: 0;
  width: 200px;
  background: #fff;
  height: calc(100vh - 220px);
  z-index: 9999
}

.bmap-demo {
  height: calc(100vh - 140px);
}

.labelbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  .labelcontent {
    border: solid 1px #fff;
    background: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;

    .iconimg {
      height: 30px;
      width: 30px;
    }

    .labeltext {
      margin-left: 10px;
    }

    // mar
  }

  .labeldown {
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;

  }
}
</style>