<template>
  <div :class="{
    'maincon': true,
    'isBlock': isBlock
  }">

    <div class="bmap-page-container">
      <el-bmap vid="bmapDemo" :bmap-manager="bmapManager" :zoom="zoom" :center="center" :events="events"
        class="bmap-demo">
        <weixing-plugin :map="map"></weixing-plugin>
        <el-bmap-polygon v-if="polygons.length && !polygondata.length" v-for="(polygon, index) in polygons"
          :visible="visible" :key="polygon.key" :vid="index" :ref="`polygon_${index}`" :path="polygon.path"
          :enable-editing="polygon.enableEditing" fillColor="#dedede" strokeStyle="dashed" :events="polygon.events">
        </el-bmap-polygon>

        <el-bmap-label v-for="item in pointsList" :key="item.id" :position="[item.lng_lat.lng, item.lng_lat.lat]"
          :visible="item.visible" :is-custom="true">
          <div class="labelbox" style="color:#fff;font-size:20px;">
            <div class="labelcontent">
              <img class="iconimg" :src="item.imgurl" />
              <div class="labeltext">
                {{ item.name || '空' }}
              </div>
            </div>
            <span class="labeldown" style="color:#000"></span>
          </div>
        </el-bmap-label>

        <el-bmap-polyline v-for="(polyline, index) in polylines" :key="index" :enable-editing="polyline.enableEditing"
          :visible="polyline.visible" :strokeColor="polyline.strokeColor" :strokeStyle="polyline.strokeStyle"
          :strokeOpacity="polyline.strokeOpacity" :strokeWeight="polyline.strokeWeight" :path="polyline.path"
          :events="polyline.events"></el-bmap-polyline>
      </el-bmap>
    </div>

  </div>
</template>

<script>

import weixingPlugin from '@/components/bmap/weixingPlugin.vue'
import VueBMap from "vue-bmap-gl";
let bmapManager = new VueBMap.BMapManager();

import { getDetail } from "@/api/data";

import { pointData, polygonData, lineData } from '@/api/dcity'

export default {
  components: {
    weixingPlugin
  },

  props: {
    pointcate: String,
    linecate: String,
    cname: String,
    // city_plate_id: {
    //   type: Number,
    // },
    isBlock: {
      props: Boolean,
      default: false
    },
    polygondata: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  mounted() {
    this.getPointData()
    this.getLineData()
  },
  watch: {

    pointcate(v) {
      this.getPointData()
    },
    linecate() {
      this.getLineData()
    },
    shi(v) {

      if (v !== 0) {
        this.getLineData()
        this.getPointData()
      }
    },

    city_plate_id(v) {


    },
    cname(v) {
      let myGeo = new BMapGL.Geocoder();
      var that = this
      // myGeo.getPoint(v + '人民政府', function (point) {
      //   if (point) {
      //     that.map.centerAndZoom(point)
      //   } else {
      //     alert('您选择的地址没有解析到结果！');
      //   }
      // }, v + '市')
      that.getPointData()
      that.getPolygonData()

    },




    polygondata: {
      handler(v) {
        // console.log('==data==', v)

        v.map(item => {
          this.handlerData(item.set)
        })
      },
      deep: true
    }


  },

  computed: {

    city_plate_id: {
      get() {
        let a = this.$store.state.currentLandData.cid
        return a
      },
    },

    shi: {
      get() {
        let a = this.$store.state.currentLandData.cid
        return a
      },

    },

    currentLandData: {
      get() {
        return this.$store.state.currentLandData
      }
    }

    // currentArea: {
    //   get() {
    //     // alert(this.$store.state.currentArea)
    //     return this.$store.state.currentArea
    //   }
    // }
  },
  data() {

    return {
      detail: {},
      drwaPolygonArr: [],
      setMapMode: '地图',
      blockoverlay: {},//板块多边形对象
      visible: true,
      polylines: [],
      polygons: [

      ],
      area: '',
      pointsList: [
        {
          // id: 0,
          name: '这是标题1',
          lng_lat: {
            lat: '1',
            lng: '1'
          },
          imgurl: ''
        }
      ],
      map: {},
      bmapManager,
      geodata: "",
      events: {
        init: (o) => {
          this.map = o
          // this.map.setMapType(BMAP_EARTH_MAP);   
          let currentname = this.cname
          let myGeo = new BMapGL.Geocoder();
          // 将地址解析结果显示在地图上，并调整地图视野
          let that = this;
          myGeo.getPoint(currentname + '市人民政府', function (point) {
            if (point) {
              o.centerAndZoom(point, 10);
              // o.addOverlay(new BMapGL.Marker(point, {title: ' '}))
              // that.setAreaLine(currentname)
              // console.log('000000000',that.currentLandData)

              //画多边形

            } else {
              alert('您选择的地址没有解析到结果！');
            }
          }, currentname + '市')

          // 将地址解析结果显示在地图上，并调整地图视野
          //  o.setViewport(points);　　
          that.getPointData()
          that.getLineData()

          that.drawCurrentLand()

        },
      },
      count: 1,
      center: [0, 0],
      zoom: 12
    };
  },
  methods: {

    //画当前地块的多变形和点
    drawCurrentLand() {
      this.getMapDetail().then(res => {

        this.createOverlay(this.detail)
        this.drawPoint(this.detail)
      })
    },

    getMapDetail() {
      var that = this
      return new Promise(function (resolve, reject) {
        getDetail({ id: that.currentLandData.id }).then(res => {
          that.detail = res.data
          that.center = [
            res.data.lng,
            res.data.lat
          ]
          resolve(res.data)
        })

      })

    },
    //画点
    drawPoint(itemdata) {

      const type5 = require('@/assets/point.png')

      const myIcon = new BMapGL.Icon(type5, new BMapGL.Size(50, 110), {
        imageOffset: new BMapGL.Size(0, 0)   // 设置图片偏移   
      })

      const point = new BMapGL.Point(parseFloat(itemdata.lng), parseFloat(itemdata.lat));
      // 创建标注对象并添加到地图  
      const marker = new BMapGL.Marker(point, { icon: myIcon });
      marker.id = itemdata.id
      this.map.addOverlay(marker);
      this.map.centerAndZoom(point, 14)
    },


    // 创建一个多变形
    createOverlay(data) {

      const allset = data.set
      const setObj = JSON.parse(allset)
      setObj.map(item => {
        // data 是一个多变形的数据
        // 画一个多变形的方法
        this.createPolygon(item, data.id)
      })


    },

    createPolygon(data, id) {
      const pointobj = data.lnglat.split(';')
      const pointArr = pointobj.map(point => {
        const lnglat = point.split(',')
        return new BMapGL.Point(lnglat[0], lnglat[1])
      })

      const polygonAttribute = {

        strokeWeight: 2,
        strokeOpacity: 0.5,
        strokeColor: '#28A7E1',
        fillColor: data.color,
        fillOpacity: 1
      }

      const overlay = new BMapGL.Polygon(pointArr, polygonAttribute)

      overlay.mode = 'polygon'
      overlay.color = data.color
      overlay.index = data.index

      if (this.index < data.index) { // 更新当前的index
        this.index = data.index
      }
      overlay.id = id

      this.map.addOverlay(overlay)

    },





    handleImg(data) {
      const res = data.map(item => {
        item.imgurl = require('@/assets/icon/jiaotong' + item.type + '.png')
        return item
      })
      return res
    },

    //获取点数据
    getPointData() {
      // alert('shi')
      // alert(this.city_plate_id)
      const params = {
        city_plate_id: this.city_plate_id,
        type: this.pointcate
      }

      if (this.city_plate_id) {
        pointData(params).then(res => {
          // console.log('===', res)
          this.pointsList = this.handleImg(res.data)
        })
      }
    },

    getLineData() {

      const params = {
        city_plate_id: this.city_plate_id,
        type: this.linecate
      }


      if (this.shi) {
        lineData(params).then(res => {



          const lineArr = res.data
          const polylineArr = lineArr.map(line => {
            const LinePointArr = JSON.parse(line.set)
            // console.log('LinePointArr', LinePointArr)
            let path = []
            LinePointArr.map(item => {
              path.push([item.lng, item.lat])
            })
            return {
              path: path,
              visible: true,
              strokeColor: line.color,	//String	//线条颜色，使用16进制颜色代码赋值。默认值为#006600
              strokeOpacity: Number(line.transparency),	//Number	线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
              strokeWeight: Number(line.width),	//Number	线条宽度，单位：像素
              strokeStyle: line.strokestyle	//Number	线条宽度，单位：像素
            }

          })
          this.polylines = polylineArr

        })
      }
    },

    // 处理数据
    handlerData(data) {
      const dataArr = JSON.parse(data) // 获取地图数据数组
      dataArr.map(obj => {
        this.drawPloygonByBD(obj)
      })
    },




    // 获取手动画 数据库api数据 画多变形
    drawPloygonByBD(obj) {
      // this.map.clearOverlays()// 添加覆盖物

      var ply = new BMapGL.Polygon(obj.multipoint, {
        strokeWeight: 2,
        // strokeColor: obj.strokecolor
        strokeStyle: 'dashed',
        fillColor: '#dedede',
        strokeColor: '#000000'


      }) // 建立多边形覆盖物
      this.blockoverlay = ply
      this.drwaPolygonArr.push(ply)

      this.map.addOverlay(ply) // 添加覆盖物
      // this.map.setViewport(ply.getPath()) // 调整视野
    },






    toChangeLocation() {

    },

    changeCenter() {
      let o = this.bmapManager.getMap()
      console.log(o)
    },

    getPolygonData() {
      // alert(this.currentArea)
      let currentAreaid
      if (this.isBlock) {
        currentAreaid = this.block
      } else {
        currentAreaid = this.currentArea
      }
      // alert('block='+currentAreaid)

      const params = {
        city_plate_id: currentAreaid,
        type: 10
      }

      polygonData(params).then(res => {
        const polygondata = res.data
        polygondata.map(item => {
          this.handlerData(item.set)
        })

      })
    },


    setAreaLine(cname) {
      if (cname) {

        var bdary = new BMapGL.Boundary();
        var that = this;
        bdary.get(cname, function (rs) {
          //这里是用户自己的函数。 

          that.drwaPolygonArr.map(ply => {
            that.map.removeOverlay(ply)
          })
          that.polygons = []
          // alert()
          if (that.isBlock || rs.boundaries.length === 0) { //自定义区域画多变形
            // if (isBlock)
            that.getPolygonData()
          } else {
            const newbounderies = rs.boundaries.map(item => {
              let single = item.split(';');
              let newItem = single.map(v => {
                return v.split(',')
              })
              const object = {
                path: newItem,

              }


              return object;
            })

            that.polygons = newbounderies;
            // that.map.setViewport(newbounderies) // 调整视野
          }

        });
      }

    }




  },


};
</script> 
<style lang="scss" scoped>
.maincon {
  position: relative;
  height: calc(100vh - 220px);
}

// .isBlock{
//   height: calc(100vh - 20px);
// }

.contrl {
  position: absolute;
  ;
  right: 0;
  top: 0;
  width: 200px;
  background: #fff;
  height: calc(100vh - 220px);
  z-index: 9999
}

.bmap-demo {
  height: calc(100vh - 140px);
}

.labelbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  .labelcontent {
    border: solid 1px #fff;
    background: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;

    .iconimg {
      height: 30px;
      width: 30px;
    }

    .labeltext {
      margin-left: 10px;
    }

    // mar
  }

  .labeldown {
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;

  }
}
</style>