import request from '@/utils/request'
import requestMock from '@/utils/requestMock' //mock数据使用  requestMock需要换成request 删除此行






//获取地图图片
export function mapPic(params){
  return request({
      url: '/frontend/bigdata/dcity/map-pic',
      method: 'post',
      params
    })
}



//获取多变形
export function lineData(params){
  return request({
      url: '/frontend/bigdata/dcity/traffic',
      method: 'post',
      params
    })
}


//获取产业点数据 type 1商业 2产业  3航空  4港口
export function pointData(params){
  return request({
      url: '/frontend/bigdata/dcity/map-spot',
      method: 'post',
      params
    })
}







//获取区位多变形数据
export function polygonData(params){
  return request({
      url: '/frontend/bigdata/dcity/traffic',
      method: 'post',
      params
    })
}









