<template>
	<div class="xianzhuang">
		<traffic></traffic>
	</div>
</template>

<script>

import traffic from '@/views/data/Traffic.vue'

export default {
	name: '1',
	components: {
		traffic
	},

	data() {
		return {
		
			

		};
	},

	mounted() {
	
	},


	methods: {
		

	},
};
</script>

<style lang="scss" scoped>
</style>