<template>
	<div class="cityLocation">

		<div class="mapcon">
			<div class="areaMap">
				<!-- <data-map-traffic :cname="cname" :isBlock="true"  :pointcate="pointcate" :linecate="linecate" 
					:city_plate_id="shi">
				</data-map-traffic> -->
				<leaflet-data-map-traffic :cname="cname" :isBlock="true"  :pointcate="pointcate" :linecate="linecate" 
					:city_plate_id="shi" ></leaflet-data-map-traffic>

			</div>
			<div class="rightaction">
				<div class="jiaotongleixing">交通类型</div>
				<div class="actionbox">
					<div class="actionitem" v-for="cate in catelist">
						<el-switch class="switch" v-model="cate.status" />
						<div class="itembox">
							<img style="width:32px;width:32px;" :src="cate.imgurl">
							<span>{{ cate.catename }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
import DataMapTraffic from '@/components/bmap/DataMapTraffic.vue'
import leafletDataMapTraffic from '@/components/leaflet/leafletDataMapTraffic.vue'

import { polygonData } from "@/api/dcity"
import leafletDataMapTrafficVue from '../../components/leaflet/leafletDataMapTraffic.vue'
// import VueBMap from 'vue-mapvgl'

import {isgaode} from '@/settings.js'
export default {
	components: { DataMapTraffic,leafletDataMapTraffic },
	data() {
		return {
			isgaode:isgaode,
			polygondata: [],
			imglist: [],
			pointcate: '3,4',
			linecate: '7,8,9',
			catelist: [
				{
					cateid: 3,
					status: true,
					imgurl: require("@/assets/icon/hangk.png"),
					catename: '航空',
					type: 'point'
				}, {
					cateid: 4,
					status: true,
					imgurl: require("@/assets/icon/gangk.png"),
					catename: '港口',
					type: 'point'
				}, {
					cateid: 7,
					status: true,
					imgurl: require("@/assets/icon/tiel.png"),
					catename: '铁路',
					type: 'line'
				}, {
					cateid: 8,
					status: true,
					imgurl: require("@/assets/icon/chengsksl.png"),
					catename: '城市快速路',
					type: 'line'
				}, {
					cateid: 9,
					status: true,
					imgurl: require("@/assets/icon/guidjt.png"),
					catename: '轨道交通(地铁)',
					type: 'line'
				},
			]
		}
	},
	computed: {

		cname: {
			get() {
				let a = this.$store.state.currentLandData.cname
				return a
			}

		},
		shi: {
			get() {
				let a = this.$store.state.currentLandData.cid
				return a
			}
	
		}
	},
	
	watch: {
		catelist: {
			handler(v) {
				let catepoint = [], cateline = [];
				v.map(item => {
					if (item.type === 'point' && item.status === true) {
						catepoint.push(item.cateid)
					}
					if (item.type === 'line' && item.status === true) {
						cateline.push(item.cateid)
					}
				})
				this.pointcate = catepoint.toString()
				this.linecate = cateline.toString()
			},
			deep: true
		},

	},
	mounted() {
		this.cname = this.$store.state.currentLandData.cname
	},
	methods: {


		getPolygonData() {
			const params = {
				city_plate_id: this.shi,
				type: 10,
				is_gaode: this.isgaode
			}

			polygonData(params).then(res => {
				const polygondata = res.data
				this.polygondata = polygondata
			})
		}

	}
}
</script>
<style lang="scss" scoped>
.actionbox {
	width: 100%;
	display: flex;
	flex-direction: column;



	.actionitem {
		margin-top: 10px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;

		.switch {
			width: 30px;
		}

		.itembox {
			width: 156px;
			height: 52px;
			background: #FFFFFF;
			border-radius: 4px;
			display: flex;
			align-items: center;

			img {
				margin: 0 10px;
			}

			span {
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #43496A;
			}
		}
	}
}

.cityLocation {
	width: 100%;
	height: calc(100vh - 100px);
	color: #333;

}

.areaMap {
	width: 100%;
	// margin: 1%;
	margin-top:1%;
	margin-left:1%;
	margin-right:1%;
	height: calc(100vh - 184px);
	background: #ddd;
}

.mapcon {
	display: flex;

	padding-right: 15px;

	.rightaction {
		flex-direction: column;

		width: 264px;
		background: #F0F3F8;
		border-radius: 4px;
		height: calc(100vh - 100px);
		margin-top: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.jiaotongleixing {
			width: 72px;
			height: 50px;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 50px;
		}
	}
}
</style>